.base-container {
}

.app-container {
  /* background-image: radial-gradient(circle at 0 0, #37c3ce, #3c889e 52%, #2e74a5 71%, #14af81); */
  background: #f7f7f7;
}

.convert-container {
  position: fixed;
  display: flex;
  align-items: center;
  height: 65px;
  color: #fff;
  background-image: linear-gradient(to right, #37c3ce, #3c889e);
  padding: 0 10px;
  top: 0;
  left: 0;
  right: 0;
}
.convert-logo {
  height: 42px;
  width: 42px;
}
.convert-text {
  font-size: 12px;
  margin: 0 10px;
  flex: 1;
}
.convert-title {
  font-weight: bold;
  line-height: 1.5;
}
.convert-subline {
}
.convert-cta {
}

@media only screen and (min-width: 375px) {
  .convert-pill {
    padding: 12px 35px;
  }
}
