body {
  font-family: 'Poppins';
  font-size: 12px;
  /* background: radial-gradient(circle at 0 0, #37c3ce, #3c9f82 32%, #2fa69c 61%, #14af81); */
  background-attachment: fixed;
  color: #fff;
  background: #f7f7f7;
}

.widget-container {
  max-width: 500px;
  margin: 0 auto;
  position: static;
}

.widget-container .details-header-initial {
  border-radius: 0;
  width: 100%;
  height: 130px;
  z-index: 0;
}

.widget-container .details-header {
  height: 229px;
}

.widget-container .details {
  background: #ffffff;
  padding-bottom: 15px;
}

@media (max-width: 500px) {
  .widget-container .details {
    min-height: 100%;
  }
}

.widget-container .details-learn {
  bottom: 17px;
  font-size: 8px;
  position: absolute;
  right: 40px;
  z-index: 1;
}

.widget-container .details-thumbnail-initial {
  margin: -30px auto 0;
  text-align: center;
  z-index: 1;
}

.widget-container .details-thumbnail-initial .thumbnail-logo {
  width: 130px;
}

.widget-container .details-thumbnail-initial .thumbnail-subline {
  position: absolute;
  width: 45px;
  height: auto;
  bottom: 10px;
  right: 35px;
}

.widget-container .details-thumbnail {
  margin-top: 15px;
}

.widget-container .details .details-copy {
  border: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;
  padding: 0;
  margin-top: 20px;
  text-align: center;
  width: auto;
  color: black;
  margin-bottom: 20px;
}

.widget-container .details .details-shares .shareOption {
  /* background: black; */
  padding: 10px 15px;
  border-radius: 8px;
  width: 85%;
  margin: 4px 0;
  color: #717b7c;
  font-size: 18px;
  font-weight: 300;
}

.widget-container .details .details-shares .shareOption img {
  /* margin-right: 15px; */
  height: auto;
  width: auto;
  max-height: 30px;
  max-width: 30px;
  vertical-align: middle;
}

.widget-container .details .details-shares .skip {
  background: initial;
  color: white;
  font-size: 18px;
  text-align: center;
  padding: 0;
  font-weight: 300;
  width: initial;
  margin-top: 10px;
}

.event .tour-dates {
  height: 100%;
  width: 100%;
}

.tour-click button {
  margin-top: 10px;
  background: #007da1;
  color: white;
  border: none;
  cursor: pointer;
  width: 132px;
  height: 30px;
  border-radius: 5px;
  box-shadow: 0 6px 4px -4px #808080;
  margin-bottom: 10px;
}

.tour-click button:hover {
  background: rgba(0, 125, 161, 0.8);
}

.tour-click .text {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
}

.event .details-getStarted {
  margin: 0 auto;
  position: initial;
}

button:focus {
  outline: none;
}

.event .details-body .tour-stop {
  border-bottom: none;
}

.event .details-body .tour-copy {
  border-bottom: none;
  padding-left: 30px;
  padding-right: 30px;
}

.widget-container .details-body .details.signup .details-copy,
.widget-container .details-body .details.signup .details-agreeSms,
.widget-container .details-body .details.signup .details-inputTitle,
.widget-container .details-body .details.signup .details-small {
  color: black;
}

.widget-container .details-body .details.signup .details-inputTitle {
  text-align: initial;
  font-weight: 500;
  font-size: 14px;
}

.widget-container .details-body .details.signup input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 6px;
  display: block;
  font-size: 16px;
  line-height: 44px;
  margin: 0 auto;
  padding-left: 20px;
  color: black;
  text-align: initial;
  outline: none;
  width: 100%;
  border: 1px solid black;
  box-sizing: border-box;
}

.widget-container .details-body .details.signup input::placeholder {
  color: black;
  font-weight: 300;
  font-size: 13px;
}

.widget-container .details-body .details.signup .details-recaptcha {
  margin-top: 25px;
  margin-bottom: 30px;
}

.widget-container .details-body .details.signup .details-agreeSms {
  line-height: 1;
  font-size: 10px;
  font-weight: 400;
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
}

.widget-container .details-body .details.signup .details-agreeSms b {
  font-weight: 600;
}

.widget-container .details-body .details.signup .details-resendCode {
  color: black;
  font-size: 14px;
  margin: 0;
  padding: 0;
  margin-top: 30px;
}

.widget-container .details-body .details.signup .details-resendButton {
  color: black;
  font-weight: 700;
  font-size: 14px;
  font-family: 'Poppins';
}

.widget-container .details-body .details.signup .details-copy {
  font-size: 20px !important;
  font-weight: 700;
  line-height: 24px;
  padding: 0;
  margin-top: 10px;
  text-align: center;
  width: auto;
  color: black;
  margin-bottom: 20px;
}

.widget-container .details-body .details.signup button {
  border: none;
  border-radius: 20px;
  color: white;
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  padding: 10px;
  font-weight: 700;
  text-align: center;
  display: block;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.widget-container .details-body .details.signup button.disabled {
  cursor: not-allowed;
}

.widget-container .details-body .details.create .details-sms {
  color: black;
}

.widget-container .details-body .details.create .details-sms p {
  font-size: 16px;
  line-height: 1.4;
}

.widget-container .details-body .details.create .details-sms .copy {
  background: #fff;
  color: #717b7c;
  width: 85%;
  border-radius: 8px;
  padding: 15px 15px;
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 18px;
  font-weight: 300;
}

.widget-container .details-body .details.create .details-created-sub {
  font-size: 16px;
  margin-bottom: -5px;
}

.widget-container .details-body .details.create .details-sms textarea {
  background: hsl(202, 72%, 58%);
  border-radius: 8px;
  border: none;
  width: 90%;
  padding: 20px;
  color: #333;
  font-size: 16px;
  line-height: 1.4;
  border-bottom: 1px solid #cce3f4;
  height: 180px;
}

.widget-container .details-body .details.create .details-created-header {
  font-size: 18px;
  margin-top: 15px;
}

.widget-container .details-body .details.create .details-action {
  color: black;
}

.details-body.upload {
  text-align: center;
}

.details-body.upload p {
  font-size: 20px;
  font-weight: 200;
  margin: 20px auto;
  width: 65%;
}

.details-body.upload label.upload,
.details-body.upload button {
  background: #fff;
  border: none;
  border-radius: 8px;
  color: #717b7c;
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-weight: 300;
  margin: 0 auto 20px;
  padding: 10px 15px;
  width: 65%;
}

.details-body.upload button:disabled {
  background-color: rgb(220, , 220);
  cursor: not-allowed;
}

.details-body.upload label.upload input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.details-body.upload .filesList {
  margin: 0 0 20px;
}

.details-body.upload .error-message {
  color: #ff2222;
  font-size: 12px;
  font-weight: 500;
}

.details-body.upload small {
  display: block;
  font-size: 8px;
  font-weight: 200;
  margin: 0 0 10px;
}

.details-body.upload .done {
  font-size: 24px;
  font-weight: 200;
  margin: 50px 0;
}

.event-type {
  font: 'Poppins';
  font-weight: 700;
  font-size: 17px;
  color: black;
  margin: 20px 20px 10px 25px;
}
