.lsi {
  font-weight: 500;
  color: #333;
  height: 100%;
  background: #fff;
}

.lsi-enable-watch {
  width: 100%;
  height: 88px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
  background-color: #dfe8f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
}

.lsi-enable-left {
  flex: 1;
}

.lsi-enable-text {
  color: #006883;
  font-size: 16px;
  font-weight: 600;
  padding-right: 10px;
}

.lsi-enable-right {
  justify-content: flex-end;
}

.lsi-enable-button {
  cursor: pointer;
  width: 120px;
  height: 50px;
  border: none;
  border-radius: 25px;
  background-color: #6bc04a;
  color: #fff;
  line-height: 50px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}

.lsi-enabled {
  background: #fff;
  color: #1b1b1b;
}
