.main-container {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background-image: radial-gradient(circle at 0 0, #37c3ce, #3c889e 52%, #2e74a5 71%, #14af81);
}

.main-promo {
  max-width: 420px;
  font-size: 16px;
  margin: 0 auto;
  padding: 0 10px;
  color: #fff;
  text-align: center;
  line-height: 1.3;
}
.main-promo p {
  font-weight: 300;
}

.main-promo ul {
  text-align: left;
  margin: 0 auto;
  width: 200px;
}

.promo-pill {
  margin: 0 auto;
  width: 200px;
}
footer {
  font-size: 14px;
  margin: 0 20px;
  margin-top: 80px;
  border-top: 1px solid hsl(131, 42%, 75%);
  padding-top: 5px;
}
.main-footer-links {
  text-decoration: none;
  margin: 0 5px;
  color: hsl(131, 42%, 85%);
}
