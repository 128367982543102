.up {
  font-weight: 500;
  color: #333;
  height: 100vh;
  background: linear-gradient(180deg, #5db5e7 250px, 0%, #efefef 100%);
  display: flex;
  flex-direction: column;
}

.up-avatar-container {
  position: relative;
  height: 90px;
  width: 90px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 15px;
}

.up-avatar-wrapper {
  height: inherit;
  width: inherit;
}

.up-avatar {
  border: 4px solid #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
}

.up-avatar-overlay {
  position: absolute;
  top: 5px;
  right: -25px;
  background: #5db5e7;
  border-radius: 90px;
  color: #fff;
  padding: 5px 10px;
  border: 2px solid #fff;
}

.up-info {
  background: #fff;
  padding: 20px 15px;
  flex: 1;
  overflow: auto;
}

.up-name {
  font-size: 18px;
}

.up-top-actions {
  display: flex;
  padding: 20px;
  align-items: center;
}

.up-label {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 4px;
  color: #777;
}

.up-close {
  flex: 1;
}

.up-edit {
  color: #fff;
  font-size: 14px;
  border-radius: 90px;
  border: 2px solid #fff;
  padding: 5px 15px;
}

.up-methods {
  margin-top: 50px;
}
