div.tour-stop {
  display: flex;
  height: 145px;
  min-width: 336px;
  flex-flow: row nowrap;
  background: hsl(225, 22%, 96%);
  margin: 10px 20px 0px 25px;
  border-radius: 10px;
  box-shadow: 0 8px 10px -4px #808080;
}

div.tour-stop-lg {
  display: flex;
  height: 180px;
  min-width: 336px;
  flex-flow: row nowrap;
  background: hsl(225, 22%, 96%);
  margin: 10px 20px 0px 25px;
  border-radius: 10px;
  box-shadow: 0 8px 10px -4px #808080;
}

.profilePicsContainer {
  margin-top: auto;
  margin-left: auto;
  margin-right: 5px;
  margin-bottom: 5px;
  min-width: 130px;
}
.profilePics {
  display: flex;
  flex-direction: row-reverse;
}
.profilePic {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 30px;
}
.profilePic:not(:last-child) {
  margin-left: -15px;
}
.profilePic img {
  width: 100%;
}

.tour-avatar {
  height: 145px;
  width: 180px;
  flex-shrink: 0;
}

.tour-avatar-lg {
  height: 180px;
  width: 180px;
  flex-shrink: 0;
}

.tour-avatar img {
  border-radius: 0px;
  width: 100%;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
}

.tour-avatar-lg img {
  border-radius: 0px;
  width: 100%;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
}

div.tour-text {
  display: flex;
  flex-direction: column;
  color: black;
  margin: 10px;
  overflow: hidden;
  flex-grow: 1;
}

.line1 {
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.line2 {
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 5px;
}

.line3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 5px;
}

@media screen and (max-width: 400px) {
  .tour-avatar {
    height: 145px;
    width: 160px;
    flex-shrink: 0;
  }

  .tour-avatar-lg {
    height: 180px;
    width: 160px;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 375px) {
  .tour-avatar {
    height: 145px;
    width: 160px;
    flex-shrink: 0;
  }

  .tour-avatar-lg {
    height: 180px;
    width: 160px;
    flex-shrink: 0;
  }
}
