.od {
  min-height: 100vh;
  background-color: #efefef;
  font-weight: 300;
  padding-bottom: 10px;
}

.detail-row {
  background-color: #fff;
  position: relative;
}
.detail-row-content {
  padding: 10px 15px;
  line-height: 1.5;
  color: #4a4a4a;
}
.detail-row-title {
  font-weight: bold;
  font-size: 16px;
}
.detail-row-border {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    to left,
    rgba(246, 246, 246, 0),
    rgba(226, 226, 226, 0.69) 16%,
    #d9d9d9 50%,
    rgba(220, 220, 220, 0.81) 83%,
    rgba(233, 233, 233, 0)
  );
}

.detail-no-maps {
  color: #bbb;
  padding: 15px;
  text-align: center;
}

.detail-add-friends {
  cursor: pointer;
  text-decoration: none;
  color: #007aff;
  position: absolute;
  right: 10px;
  top: 12px;
  font-size: 14px;
}

.detail-link {
  cursor: pointer;
  text-decoration: none;
  color: #007aff;
}

.detail-link.leave,
.detail-link.opt-out {
  color: #ff4747;
}

.om {
  margin-bottom: 10px;
}

.oed {
  margin: 10px 0;
}
.om-amount {
  font-weight: bold;
  border-bottom: 1px solid #e9e9e9;
}
.om-row-content {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  line-height: 1.5;
  color: #4a4a4a;
}
.om-avatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 10px;
}
.om-going-text {
  color: #65c276;
  font-weight: bold;
  margin-left: 5px;
}

.contextual-actions {
  cursor: pointer;
  display: block;
  margin: 10px 0;
  padding: 10px 15px;
  color: #007aff;
  text-decoration: none;
}
