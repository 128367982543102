.lsl-links-container {
  border-radius: 6px;
  border: solid 1px #e3e5e8;
  background-color: #f5f6f8;
  margin: 20px auto;
  max-width: 370px;
  padding: 20px;
}

.lsl-links-header {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #02293f;
}

.lsl-link {
  display: block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
  background-color: #01d3c5;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  line-height: 50px;
  margin: 10px 0 0;
}

.lsl-link-footer {
  font-size: 13px;
  text-align: center;
  color: #02293f;
  max-width: 240px;
  margin: 0 auto;
  line-height: 1.4;
}
