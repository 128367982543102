.outing-container {
  background: #ffffff;
  margin: 0 auto;
  max-width: 635px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;

}

.content-container {
  position: relative;
  flex-grow: 1;
}

.conversation-container {
  /* padding: 0 10px; */
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

@media screen and (max-width: 400px) {
  .conversation-container {
    padding: 0;
    padding-bottom: 80px;
  }
}

.send-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 600px;
}

.invite-wrapper {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

.invite-container {
  left: 0;
  position: fixed;
  right: 0;
  top: 24vh;
}

.invite-header {
  font-size: 22px;
  margin-top: 40px;
}

.invite-container .details-getStarted {
  all: unset;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  box-shadow: 1px 1px 5px 0 rgb(104, 193, 121, 0.5);
  display: block;
  margin: 0 auto;
  overflow: auto;
  text-align: center;
  width: 300px;
}

.invite-container .details-content .getStarted {
  -webkit-text-fill-color: rgba(92, 104, 105, 1);
  -webkit-opacity: 1;
  background: #fff;
  color: rgba(92, 104, 105, 1);
  border-radius: 6px;
}

.invite-container .details-content .getStarted:hover {
  background: #ddd;
}

.invite-container .getStarted-bullet {
  padding: 4px 25px;
}

.invite-container .details-content {
  padding: 40px 10px;
}

.invite-container .details-getStarted {
  background-color: rgba(0, 0, 0, 0.85);
}

.invite-container .details-getStarted .details-copy {
  font-size: 22px;
  margin-bottom: 20px;
}

.standalone-text {
  margin: 5px;
}

.fullscreen-tooltip-container {
  cursor: pointer;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1;
}

.fullscreen-tooltip {
  -webkit-transform: translate(-50%, -50%);
  background: #fbfbfb;
  border: 3px solid #63bc7a;
  border-radius: 5px;
  font-size: 16px;
  left: 50%;
  margin: 0 auto;
  padding: 30px;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
}
