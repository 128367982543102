.loading-container {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  /* background-image: radial-gradient(circle at 0 0, #37c3ce, #3c9f82 32%, #2fa69c 61%, #14af81); */
  /* background-image: radial-gradient(circle at 0 0, #37c3ce, #3c889e 32%, #2e74a5 61%, #14af81); */
  /* background: #37c3ce */
  background: #f7f7f7;
}

.loading-content {
}

.logo {
  display: block;
  margin: 0 auto;
  width: 60px;
  background: #fff;
  border-radius: 10px;
}

.loading-dots {
  display: block;
  margin: 0.2rem auto;
  width: 50px;
}
