.InviteCompleteContainer {
  text-align: center;
  margin: 20px 0;
}

.InviteCompleteText {
  font-size: 24px;
  margin: 30px 0;
}

.InviteCompleteButton {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  background-color: #37c3ce;
  cursor: pointer;
  border-radius: 99px;
  padding: 16px 35px;
  text-decoration: none;
}
