.details-getStarted {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
}

.invited {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}

.line2 {
  font-weight: 100;
}

.details-content {
  margin-top: 10px;
}

.details-getStarted .details-copy {
  margin-bottom: 10px;
}

.getStarted-bullets {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.getStarted-bullet {
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  padding: 4px 0;
  text-align: left;
}

.getStarted-bullet.noapp {
  text-align: center;
}

.getStarted-bullet .icon,
.getStarted-bullet .text {
  display: inline-block;
  vertical-align: middle;
}

.getStarted-bullet .icon {
  margin-right: 10px;
  text-align: center;
  width: 22px;
}

.getStarted-bullet .icon img {
  vertical-align: initial;
  max-width: 16px;
  max-height: 18px;
}

.details-getStarted .getStarted {
  background-color: #027da1;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin: 45px 0 0;
  padding: 0;
  text-align: center;
  bottom: 0;
  border: 0;
  width: 280px;
  height: 50px;
  border-radius: 5px;
}

.details-getStarted .getStarted:hover {
  background: rgba(0, 125, 161, 0.8);
}

.desktopContinue {
  background: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  width: 65%;
  margin: 50px 0;
  color: #717b7c;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}
