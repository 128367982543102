.flip-container {
  display: flex;
  flex-direction: column-reverse;
  /* position: absolute; */
  padding-bottom: 40px;
  top: 75px;
  left: 0;
  right: 0;
  /* overflow-y: auto; */
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.conversation {
  position: relative;
}

.pills-container {
  padding: 10px 0 0 0;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.pills-container::-webkit-scrollbar {
  display: none;
}

.pills {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 8px;
  padding: 0 10px;
}
.pills::after {
  content: "";
  flex: 0 0 2px;
}
.pill {
  background: #fff;
  color: #333;
  padding: 0 12px;
  border: rgba(0, 0, 0, 0.5) 0.5px solid;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  line-height: 28px;
  text-wrap: nowrap;
}

.meet-up-container {
  background: #eebb14;
  color: #fff;
  padding: 15px 12px;
  border-radius: 5px;
  margin: 8px;
  display: block;
  cursor: pointer;
  text-decoration: none;
}
.meet-up-arrow-container {
  float: right;
}
.meet-up-arrow {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  border-left: 5px solid white;
}

.message-container {
  /* padding: 0 10px; */
}
.message-separator {
  height: 1px;
  width: 100%;
  margin-top: 10px;
  opacity: 0.95;
  background-image: linear-gradient(
    to left,
    rgba(246, 246, 246, 0),
    rgba(226, 226, 226, 0.69) 16%,
    #d9d9d9 50%,
    rgba(220, 220, 220, 0.81) 83%,
    rgba(233, 233, 233, 0)
  );
}
.bang-date-container {
  margin-top: 30px;
  margin-bottom: 5px;
  position: relative;
  text-align: center;
}
.bang-date-container.initial {
  margin-top: 15px;
  margin-bottom: 15px;
}
.bang-date {
  font-size: 14px;
  /* border: 1px solid #95c0eb; */
  border-radius: 90px;
  color: #95c0eb;
  text-align: center;
  display: inline-block;
  background-color: #fff;
  padding: 6px 28px;
  position: relative;
}
.bang-line {
  position: absolute;
  bottom: 7px;
  height: 1px;
  width: 100%;
  background-color: #e9e9e9;
}
