html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
}

img {
  vertical-align: top;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pill-button {
  display: inline-block;
  font-size: 12px;
  background-color: #fff;
  color: #37c3ce;
  border-radius: 99px;
  padding: 12px 20px;
  text-decoration: none;
}
.pill-button:hover {
  cursor: pointer;
}
