.message {
  display: flex;
  flex-direction: row;
}
.message.right {
  /* justify-content: flex-end; */
}
.message-avatar {
  flex: none;
  border-radius: 100% 100%;
  width: 38px;
  height: 38px;
  margin: 0 8px;
  background-repeat: no-repeat;
  background-size: cover;
}

.message-avatar.empty {
  background: #ccc;
  text-align: center;
  font-size: 20px;
  padding-top: 5px;
}

.message-content {
  flex: 1;
  /* background: hsl(192, 24%, 93%); */
  /* padding: 12px; */
  /* border-radius: 0 5px 5px 5px; */
  /* max-width: 330px; */
  margin-top: 5px;
  margin-left: 6px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.message-content.right {
  /* border-radius: 5px 0 5px 5px; */
  /* background: hsl(252, 24%, 94%); */
  /* margin-right: 0; */
  /* margin-left: 30px; */
}
.message-details {
  display: flex;
}
.message-name {
  flex: 1;
  margin-right: 5px;
  color: #02293f;
  font-size: 14px;
  font-weight: bold;
}
.message-name.current {
  color: #37c3ce;
}
.message-timestamp {
  flex: none;
  color: rgb(145, 145, 145);
  font-size: 11.7px;
  /* align-self: flex-end; */
}
.message-body {
  margin-top: 3px;
  margin-right: 15px;
  margin-bottom: 5px;
  hyphens: none;
  word-break: normal;
  word-break: break-word;
}
.message-photo-container {
  overflow: hidden;
  margin-top: 5px;
}
.message-photo {
  border-radius: 5px;
  max-height: 250px;
  max-width: 100%;
}
.message-text {
  color: #02293f;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  white-space: pre-wrap;
}
.message-rich-url-link {
  text-decoration: none;
  color: initial;
  font-weight: 400;
}
.message-rich-url-container {
  display: flex;
  padding-top: 10px;
}
.message-rich-image {
  flex: 0 0 auto;
}
.message-rich-image img {
  border-radius: 10px;
  width: 140px;
}
.message-rich-text {
  flex: 1 1 auto;
  padding: 5px 8px;
  text-align: vertical;
}
.message-rich-title {
  padding-bottom: 5px;
}
.message-rich-url {
  color: #37c3ce;
  font-size: 14px;
}

.message-rich-site-url-container {
  padding-top: 10px;
  padding-bottom: 5px;
}

.message-rich-site-url {
  font-size: 14px;
  font-weight: bold;
  vertical-align: middle;
}

.message-rich-url-favicon img {
  width: 18px;
  height: 18px;
  padding-right: 5px;
  display: inline;
}

.message-rich-site-url-link {
  font-weight: bold;
  color: #37c3ce;
  text-decoration: none;
  font-size: 14px;
}

.message-rich-site-title {
  line-height: 1.4;
  padding: 5px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 220px;
}

.message-rich-desc {
  line-height: 1.3;
}
