* {
  /* outline: 1px solid hsla(195, 53%, 79%, 0.274); */
}

.topbar {
  position: relative;
  width: 100%;
  height: 75px;
  background-image: linear-gradient(to right, #3c889e, #37c3ce);
  color: #fff;
}
.topbar-bar {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  max-width: 635px;
  margin: 0 auto;
  z-index: 3;
}
.topbar-open {
  height: 400px;
}
.topbar-cover-container {
  height: inherit;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
}
.cover-image {
  background-color: #fff;
  height: inherit;
}
.cover-image-collapsed {
  height: 75px;
  overflow: hidden;
}
.cover-shade {
  position: absolute;
  top: 0;
  height: 75px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
}
.cover-shade-hide {
  opacity: 0;
}
.cover-checkmark {
  position: absolute;
  right: 22px;
  bottom: -24px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-color: #d9d9d9;
  padding: 15px 12px;
  cursor: pointer;
}
.cover-checkmark-filled {
  background-image: linear-gradient(to right, #3c889e, #37c3ce);
}

.topbar-back-button {
  position: absolute;
  left: 0;
  top: 0;
  padding: 26px 15px;
}
.topbar-back-button:hover {
  cursor: pointer;
}

.topbar-add-to-cal {
  cursor: pointer;
  font-size: 12px;
  padding: 24px 18px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}

.avatar-container {
  cursor: pointer;
  position: absolute;
  width: 35px;
  height: 35px;
  left: 15px;
  top: 20px;
}

.avatar {
  width: inherit;
  height: inherit;
  border: 3px solid #fff;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.topbar-details-button {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 20px;
  width: 35px;
  height: 35px;
}

.topbar-details-button.collapsed {
  position: absolute;
  right: 20px;
  width: auto;
}
.topbar-details-shade {
  background: #333;
  opacity: 0.3;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 100%;
  border: 2px solid orange;
}
.topbar-details-dots {
  font-size: 30px;
  line-height: 0.5;
  right: 7px;
  top: 0;
  position: absolute;
  /* text-shadow: 0 0 3px rgba(0, 0, 0, 1); */
}

.select-showtime-copy {
  cursor: pointer;
}

.outing-tooltip {
  animation: showElement 0s 2s forwards;
  background-color: white;
  border: 2px grey solid;
  border-radius: 6px;
  color: #333;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  position: absolute;
  text-align: center;
  visibility: hidden;
  z-index: 1;
}
.outing-tooltip::before {
  border-style: solid;
  border-width: 10px;
  content: '';
  margin-right: -5px;
  position: absolute;
}
.outing-tooltip::after {
  border-style: solid;
  border-width: 7px;
  content: '';
  margin-left: -5px;
  position: absolute;
}

.open-details.outing-tooltip {
  right: 10px;
  top: 60px;
}
.open-details.outing-tooltip::before {
  border-color: transparent transparent grey transparent;
  bottom: 100%;
  right: 11px;
}
.open-details.outing-tooltip::after {
  border-color: transparent transparent white transparent;
  bottom: 100%;
  right: 8px;
}

.topbar-title {
  text-align: center;
  padding-top: 27px;
}

.cover-detail {
  text-align: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.cover-detail-gradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 210px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
}
.cover-detail-title {
  margin: 5px 55px;
  font-size: 24px;
  font-weight: 700;
}
.cover-detail-title-collapsed {
  font-size: 18px;
}
.cover-detail-subtitle {
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 5px;
}
.cover-detail-theater {
  font-size: 13px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  margin-top: 10px;
  cursor: pointer;
}

.cover-detail-theater-locationPin {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border-radius: 5px;
}
.cover-detail-date {
  font-size: 13px;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}
.cover-detail-date-collapsed {
  font-size: 12px;
}
.cover-detail-livestream {
  margin-top: 12px;
}
.cover-detail-livestream-button {
  border-radius: 8px;
  margin: 0 auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #01d3c5;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
  outline: none;
  border: none;
  line-height: 44px;
  vertical-align: center;
  display: flex;
  width: 200px;
  padding: 0 15px;
  cursor: pointer;
}
.cover-detail-livestream-button-text {
  flex: 1;
  text-align: left;
}
.cover-detail-livestream-google-meet {
  font-size: 12px;
  font-weight: 500;
  color: #01d3c5;
  margin-top: 8px;
  cursor: pointer;
  display: inline-block;
}

.topbar-add-to-cal-button {
  position: absolute;
  right: 15px;
}
.fantic-logo {
  /* height: 20px; */
  /* width: 100px; */
  position: absolute;
  left: 20px;
  top: 15px;
}

@media only screen and (min-width: 375px) {
  .cover-image {
    height: 400px;
  }
  .cover-image-collapsed {
    height: 75px;
    overflow: hidden;
  }
}
