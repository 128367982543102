.suggest-meet-up-search {
  text-align: center;
  margin: 15px 15px;
}

.suggest-meet-up-search input {
  width: 100%;
  border: 0;
  background: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  outline: none;
}

.venue-item-container {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 15px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
}

.venue-item-category-pic {
  background: #65c276;
  background-image: linear-gradient(to right, #3c889e, #37c3ce);
  margin: 5px 0;
  padding: 8px 4px;
  border-radius: 5px;
  margin-right: 7px;
}

.venue-item-details {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.venue-item-details-address {
  color: #aaa;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin-top: 2px;
  margin-right: 10px;
}

.venue-item-distance {
  font-size: 12px;
  white-space: nowrap;
  align-self: flex-start;
  margin-top: 9px;
}
