.bang {
  width: 100%;
  text-align: center;
}
.bang-text button {
  display: block;
  margin: 10px auto;
  padding: 15px 20px;
  outline: none;
  appearance: none;
  background: #37c3ce;
  border: none;
  color: white;
  border-radius: 50px;
  cursor: pointer;
}
.bang-header {
  padding-bottom: 5px;
}
.bang-avatar,
.bang-icon {
  width: 36px;
  height: 36px;
}
.bang-avatar {
  border-radius: 100%;
}
.bang-icon {
}
.bang-icon-leave {
  display: inline-block;
  margin-top: -1px;
  margin-right: 10px;
  vertical-align: top;
}
.bang-text {
  line-height: 1.2;
  font-size: 12px;
  color: rgb(145, 145, 145);
}
.bang-timestamp {
  margin-top: 5px;
  color: rgb(145, 145, 145);
  font-size: 10px;
}
