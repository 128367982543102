.details {
  overflow: hidden;
}

.details-header {
  height: 78px;
  background-size: cover;
  color: white;
  position: relative;
  text-align: center;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}

.details-title {
  font-size: 30px;
  font-weight: 200;
  letter-spacing: -1px;
  text-shadow: 0px 0px 10px #000;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
}

.details-learn {
  bottom: 5px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 300;
  line-height: inherit;
  position: absolute;
  right: 5px;
  text-decoration: none;
  z-index: 10;
}

.details .details-copy {
  color: rgb(74, 74, 74);
  padding-bottom: 10px;
  text-align: center;
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid rgba(217, 217, 217, 0.5);
  margin-bottom: 20px;
}

.details-shares {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  min-height: 80vh;
  overflow: hidden;
}

.friendInvLine {
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  margin: 20px;
}

.linkCopy {
  color: black;
  flex-grow: 1;
  margin-right: 20px;
  font-size: 20px;
  font-weight: 200;
  line-height: 24px;
  text-decoration: underline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  margin-left: 10px;
  height: 25px;
}

.linkCopyDiv {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 9px;
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 5px;
  overflow: hidden;
}

.linkCopyButton button {
  background: #007da1;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 6px 4px -4px #808080;
  margin-right: 10px;
}

.linkCopyButton button:hover {
  background: rgba(0, 125, 161, 0.8);
}

.linkCopyButton .text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.avatarContainer {
  max-width: 800px;
  margin: 0 auto;
}

.avatarPics {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 100%;
}

.avatarPic {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 15%;
  max-width: 65px;
}

.avatarPic:not(:last-child) {
  margin-left: -25px;
}

.avatarPic img {
  width: 100%;
  display: block;
}

.icons-container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  gap: 6px;
}

.shareOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: none;
  background: none;
}

.icon-circle {
  width: 55px;
  height: 55px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
}

.icon-circle img {
  width: 100%;
  height: auto;
}

.skip {
  color: white;
  text-align: center;
  font-weight: 525;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
  line-height: 50px;
  white-space: nowrap;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-top: 15px;
  text-decoration: underline;
}

.shareOption span {
  color: white;
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  /* line-height: 30px; */
  font: 'Poppins';
}

.logoutContainer {
  text-align: center;
  width: 100%;
}
.logout {
  background: none;
  border: none;
  cursor: pointer;
  margin: 40px 0 10px;
  color: #fff;
}
.logout:hover {
  text-decoration: underline;
}

.details-created-header {
  font-size: 30px;
  font-weight: 300;
}

.details-created-subHeader {
  font-size: 24px;
  font-weight: 300;
  margin-top: 1em;
}

.details-sms textarea {
  font-family: 'Roboto', sans-serif;
  height: 200px;
  margin-top: 1em;
  padding: 10px;
  resize: none;
  width: 80%;
}

.details-sms p {
  padding: 0 20px;
}

@media only screen and (max-width: 768px) {
  .linkCopyDiv {
    border-radius: 8px;
    max-width: 90%;
  }
}

@media only screen and (max-width: 480px) {
  .linkCopyDiv {
    border-radius: 5px;
    max-width: 90%;
  }
}

@media only screen and (max-width: 375px) {
  .details-shares {
    padding: 10px;
    font-size: 14px;
    height: 550px;
  }

  .friendInvLine span {
    font-size: 18px;
  }

  .avatarPic {
    width: 15%;
    max-width: 40px;
  }

  .linkCopy,
  .linkCopyButton button {
    font-size: 16px;
  }

  .details-shares,
  .avatarContainer {
    max-width: 100%;
    padding: 5px;
  }

  .icon-circle {
    width: 45px;
    height: 45px;
  }

  .icons-container {
    gap: 4px;
  }
}
