.details.create .details-action {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 300;
  margin: 20px 0 40px;
  text-align: center;
  word-wrap: break-word;
}

.details.create .details-sms {
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
}

.details.create .details-sms a {
  color: #37c3ce;
  margin: 20px 0;
}

.details-sms button {
  appearance: none;
  background: #37c3ce;
  border: none;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  outline: none;
  padding: 15px 50px;
}
