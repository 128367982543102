.details.signup {
  padding: 10px 20px;
}

.headerEventTitle {
  color: #4a4a4a;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  margin: 0 0 7px;
  text-align: center;
}

.headerTitle {
  color: #4a4a4a;
  font-size: 24px;
  font-weight: 300;
  margin-top: 20px;
  text-align: center;
}

.widget-container .details.signup .details-copy {
  border: none;
  color: #4a4a4a;
  font-size: 14px !important;
  line-height: 18px;
  margin: 0 auto;
  margin-bottom: 37px;
  padding: 0;
  text-align: center;
  width: initial;
}

.details.signup button {
  background-color: #027da1;
  border: none;
  border-radius: 99px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  line-height: 48px;
  text-align: center;
  display: block;
  margin: 20px 0;
  width: 100%;
}

.details.signup button:hover {
  background: rgba(0, 125, 161, 0.8);
}

.details.signup button.disabled {
  filter: grayscale(100%);
  cursor: not-allowed;
}

.details.signup button.disabled:hover {
  background-color: #37c3ce;
}

.details-small {
  color: rgb(155, 155, 155);
  font-size: 12px;
  margin: 0 0 10px 0;
  text-align: center;
}

.details-agreeSms {
  color: rgb(155, 155, 155);
  font-size: 12px;
  line-height: 1;
  margin: 0 auto;
  text-align: center;
  width: 280px;
}

.details-inputTitle {
  text-align: center;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 20px;
}

.details input {
  background: #black;
  border: 1px solid #f5a623;
  border-radius: 6px;
  display: block;
  font-size: 18px;
  line-height: 48px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  width: 100%;
}

.details.signup .details-verify {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin: 38px 0 3px;
  text-align: center;
}

.details-resendCode {
  color: #4a4a4a;
  font-size: 12px;
  margin: 17px 0 19px;
  text-align: center;
}

.details.signup .details-resendButton {
  all: initial;
}

.details.signup .details-resendButton {
  color: #37c3ce;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.details.signup .details-resendButton:hover {
  background-color: initial;
}

.details-privacy {
  margin: 20px auto;
  text-align: center;
  vertical-align: middle;
}

.details .details-privacy input[type='checkbox'] {
  -webkit-appearance: checkbox;
  cursor: pointer;
  display: inline-block;
  height: 18px;
  margin-right: 10px;
  width: 18px;
}

.details-privacyText {
  color: #4a4a4a;
  display: inline-block;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
}

.details-privacyText a {
  color: #37c3ce;
  text-decoration: none;
}

.details-watchVerify {
  color: #9b9b9b;
  font-size: 12px;
  line-height: 18px;
  margin: 17px 0;
  text-align: center;
}

.details.widget .headerEventTitle {
  display: none;
}

.details.widget .details.signup {
  margin: 0 12px;
}

.details.widget .details.signup .details-copy {
  margin: 14px auto 10px;
}

.details.widget .details.signup .details-privacyText {
  line-height: 10px;
}

.details.widget .details.signup button {
  margin-top: 5px;
}

.details-recaptcha {
  margin: 0 auto;
  max-width: 305px;
}

@media screen and (min-width: 360px) {
  .details-recaptcha {
    transform: scale(0.75);
    -webkit-transform: scale(0.75);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

@media screen and (min-width: 375px) {
  .details-recaptcha {
    transform: scale(0.88);
    -webkit-transform: scale(0.84);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

@media screen and (min-width: 390px) {
  .details-recaptcha {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

@media screen and (min-width: 430px) {
  .details-recaptcha {
    transform: scale(1);
    -webkit-transform: scale(1);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
