.send {
  height: 55px;
  background: hsl(204, 17%, 93%);
  border-radius: 90px;
  margin: 0 16px;
  margin-bottom: 10px;
  position: relative;
}

@media screen and (max-width: 400px) {
  .send {
    margin: 0 7px;
    margin-bottom: 10px;
  }
}

.send-border {
  width: 100%;
  height: 1px;
  opacity: 0.95;
  background-image: linear-gradient(to right, #3c889e, #37c3ce);
}
.send-top {
  display: flex;
  height: 55px;
  align-items: center;
}

.send-white-bg {
  background: #fff;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.send-top .outing-tooltip {
  animation: showElement 0s 2s forwards;
  bottom: 60px;
  left: 10px;
  visibility: hidden;
}
.send-top .outing-tooltip::before {
  border-color: grey transparent transparent transparent;
  left: 10px;
  top: 100%;
}
.send-top .outing-tooltip::after {
  border-color: white transparent transparent transparent;
  left: 17px;
  top: 100%;
}
@keyframes showElement {
  to {
    visibility: visible;
  }
}

.open-send-actions-icon {
  color: #37c3ce;
  /* border: 2px solid #37c3ce; */
  border-radius: 100%;
  margin-top: 2px;
  width: 25px;
  height: 25px;
  position: relative;
}
.open-send-actions-plus-sign {
  font-weight: bolder;
  font-size: 20px;
  left: 3px;
  top: -5px;
  position: absolute;
}
.send-action-no-showtimes {
  color: #555;
  padding: 10px 20px;
  max-width: 230px;
}
.send-action-no-showtimes h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.send-actions-popup {
  position: absolute;
  bottom: 55px;
  left: 10px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #37c3ce;
  box-shadow: 1px 1px 2px 0 hsl(131, 42%, 80%);
  overflow: hidden;
  min-width: 230px;
  padding: 10px 0;
}
.subtitle {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  color: #919191;
  padding-left: 10px;
  /* padding-top: 15px; */
  border-top: 1px solid #ddd;
  margin: 10px 0;
}
.send-action {
  color: #555;
  cursor: pointer;
  padding: 10px 20px;
}
.send-action:hover,
.send-action:active {
  background: #e1e1e1;
}
.open-send-actions {
  padding-bottom: 13px;
  padding-left: 10px;
  padding-top: 20px;
  cursor: pointer;
}
.send-input-div {
  flex-grow: 1;
}
.send-input {
  -webkit-appearance: none;
  padding: 0 13px;
  font-size: 16px;
  outline: none;
  border: 0;
  width: 100%;
  height: 70%;
  box-shadow: none;
  background: transparent;
}
.send-input::placeholder {
  color: rgba(2, 41, 63, 0.51);
  font-weight: 500;
}
.send-bottom {
  display: flex;
  height: 55px;
}
.send-options {
  flex: 1;
}
.send-button {
  flex: none;
  padding: 12px 15px;
  margin-right: 15px;
  font-weight: 500;
  border-radius: 90px;
  font-size: 14px;
  background: #68bcea;
  text-align: center;
  color: #fff;
}
.send-button:hover {
  cursor: pointer;
}
.send-ready {
  background-color: #37c3ce;
}

.outing-promo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 290px;
  opacity: 0.95;
  background-image: radial-gradient(circle at 0 0, #37c3ce, #3c9f82 32%, #2fa69c 61%, #14af81);
  text-align: center;
  color: #fff;
  font-weight: normal;
  line-height: 1.6;
  font-size: 14px;
}

.outing-promo-icon {
  height: 50px;
  margin-top: 40px;
  margin-bottom: 14px;
}

.outing-promo-title {
  font-weight: bold;
}

.outing-promo-button {
  border-radius: 99px;
  background-color: #ffffff;
  color: #37c3ce;
  font-weight: bold;
  line-height: 40px;
  width: 75%;
  margin: 0 auto;
  margin-top: 25px;
  display: block;
  text-decoration: none;
}

.outing-promo-close-button {
  position: absolute;
  top: 3px;
  right: 15px;
  font-weight: 100;
  font-size: 24px;
  cursor: pointer;
}
