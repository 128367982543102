.tour-info {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow: hidden;
}

.tour-copy {
  line-height: 30px;
  text-align: center;
  color: black;
  font: 'Poppins';
  font-weight: 700;
  font-size: 17px;
}

.bold {
  font-size: 30px;
  line-height: 35px;
}

.bold2 {
  font-weight: 700;
  text-align: center;
}

.tour-header {
  background-size: cover;
  color: white;
  height: 360px;
  position: relative;
  text-align: center;
}

.tour-header:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
}

.tour-header .tour-name {
  font-size: 28px;
  font-weight: 200;
  letter-spacing: -1px;
  text-shadow: 0px 0px 10px #000;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
}

.tour-dates {
  height: 478px;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  margin-bottom: 15px;
}

.tour-loading {
  text-align: center;
  margin: 50px;
  font-size: 24px;
}
