.loading {
  background: none;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #888;
}

.date-picker-container {
  display: flex;
  padding: 0 15px;
  border-bottom: 5px solid #f1f1f1;
  color: #555;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.zip-picker-container {
  display: flex;
  align-items: center;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  color: #555;
  padding: 10px 15px;
  border-bottom: 10px solid #f1f1f1;
}

.zip-picker-container input {
  border: none;
  margin-left: 5px;
  padding-left: 3px;
  -webkit-appearance: none;
  flex: 1 1 auto;
}

.zip-picker-container button {
  text-transform: uppercase;
  background: none;
  border: none;
  -webkit-appearance: none;
}

.date-picker-item-container {
  margin-right: 15px;
  padding: 15px 5px;
  padding-bottom: 10px;
  white-space: nowrap;
}

.date-picker-item-active {
  border-bottom: 2px solid #3c889e;
}

.theater-item-container {
  padding: 20px 15px;
  padding-bottom: 15px;
  border-bottom: 10px solid #f1f1f1;
  color: #555;
}

.theater-item-header {
  display: flex;
  align-items: baseline;
}

.theater-item-title {
  margin-bottom: 10px;
  font-size: 15px;
  flex: 1 1 auto;
}

.theater-item-distance {
  font-size: 12px;
  color: #888;
  white-space: nowrap;
}

.showing-item-container {
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}

.showing-item-times {
  border: solid 1px #e1e1e1;
  border-radius: 60px;
  color: #37c3ce;
  cursor: pointer;
  font-size: 12px;
  margin: 4px 3px 4px 0;
  padding: 10px 10px;
  /* width: 90px; */
  text-align: center;
  white-space: nowrap;
}

.showing-item-times-active {
  background-color: #37c3ce;
  color: #fff;
}

.suggest-button {
  position: fixed;
  color: #fff;
  cursor: pointer;
  background: #37c3ce;
  border-radius: 99px;
  line-height: 48px;
  margin: 0 auto;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  width: 242px;
}
