.suggestions-container {
  padding: 0 15px;
}

.suggestion-container {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid #ddd;
}

.suggestion-container-info {
  flex: 1 1 auto;
}

.suggestion-container-info h1 {
  font-size: 16px;
  margin: 0;
  margin-bottom: 2px;
}
.suggestion-container-info h2 {
  margin: 0;
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 16px;
}
.suggestion-add-to-outing {
  color: #007aff;
  cursor: pointer;
  text-decoration: none;
  padding-right: 8px;
}
.suggestion-container-button {
  flex: 0 0 100px;
  text-align: center;
  position: relative;
}
.suggestion-container-button button {
  appearance: none;
  border: 3px solid #37c3ce;
  background: none;
  border-radius: 90px;
  padding: 10px 30px;
  color: #37c3ce;
  font-size: 16px;
  cursor: pointer;
  padding-bottom: 5px;
  padding-top: 15px;
}

.suggestion-container-button-active button {
  background: #37c3ce;
  color: white;
  padding: 10px 30px;
}

.upvote-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #37c3ce transparent;
  position: absolute;
  left: 44px;
  top: 10px;
}

.suggestion-meetup-container {
  display: flex;
}

.suggestions-modal {
  position: fixed;
  bottom: 0;
  background: #eee;
  width: 300px;
  right: 0;
  left: 0;
  margin: 0 auto;
  box-shadow: 0 0 5px 1px #d1d1d1;
  border-radius: 10px 10px 0 0;
  text-align: center;
  padding: 15px;
}

.suggestions-modal-text {
  font-size: 16px;
  padding-bottom: 20px;
  line-height: 1.4;
  width: 230px;
  text-align: center;
  margin: 0 auto;
}

.suggestions-modal-button {
  display: block;
  color: #444;
  background: #cecece;
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  line-height: 50px;
  margin: 10px 0;
}
